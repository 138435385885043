import {
  AddressType,
  AddressComponent,
  GeocodingAddressComponentType,
  ReverseGeocodeResponse,
} from '@googlemaps/google-maps-services-js'
import type { OrderingAddress } from '@/providers/googleMaps'
import type { Branding } from './apiClient'
import { formatAddress, formatPhone } from './formatters'
import { parseHTMLEntities, OrderMethod, DeliveryFleet } from './utils'

export enum ChargeType {
  PERCENTAGE = 'PERCENTAGE',
  DOLLAR = 'DOLLAR',
}

export enum WeekDay {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

interface WeeklySchedule {
  weekDays: WeekDay[]
  timeslots: { startTime: string; endTime: string }[]
}

export interface VenueOrderingMethod {
  orderMethod: OrderMethod
  waitTime: number
  notice: string
  weeklySchedule: WeeklySchedule[]
  isASAPAvailable: boolean
  nextAvailable: string | null
  isSchedulingAvailable: boolean
  orderThrottlingLimitReached: boolean
}

export interface ServerVenue {
  venueName: string
  venueXRefID: string
  venueCalorieStatement: string
  venueOrderingMethods: VenueOrderingMethod[]
  maxSchedulingDays: number
  venueAddress1: string
  venueAddress2: string
  venuePhone: string
  venueLatitude: number | null
  venueLongitude: number | null
  venueState: string
  venueCity: string
  parentGroupName: string | null
  active: boolean
  disableCutlery: boolean
  disableTip: boolean
  venueCountry: string
  guestCheckoutEnabled: boolean
  // As of 10/04/2023, currency is technically nullable, but since OO requires currency, this is set to a non-null string
  venueCurrency: string
  venueIsTest: boolean
}

export interface VenueBranding {
  bannerUrl: string
  logoUrl: string
  primaryColor: string
  secondaryColor: string
}

export interface Venue {
  name: ServerVenue['venueName']
  venueXRefID: ServerVenue['venueXRefID']
  calorieStatement: ServerVenue['venueCalorieStatement']
  venueOrderingMethods: VenueOrderingMethod[]
  maxSchedulingDays: ServerVenue['maxSchedulingDays']
  phone: ServerVenue['venuePhone']
  latitude: ServerVenue['venueLatitude']
  longitude: ServerVenue['venueLongitude']
  address: string
  active: boolean
  disableCutlery: boolean
  disableTip: boolean
  venueCountry: string
  branding: VenueBranding
  parentGroupName: string | null
  guestCheckoutEnabled: ServerVenue['guestCheckoutEnabled']
  venueCurrency: ServerVenue['venueCurrency']
  venueIsTest: ServerVenue['venueIsTest']
}

export interface Delivery {
  minSubtotal: string | null
  waitTime: number
  exemptionThreshold: string | null
  charge: { type: ChargeType; amount: string }
  fleet: DeliveryFleet
  deliveryFeeTotal: string
}

type MenuEntityAlwaysSchedule = {
  type: 'ALWAYS'
}

type MenuEntityWeeklySchedule = {
  type: 'WEEKLY'
  times: { startTime: string; endTime: string }
}

type MenuEntitySchedule = MenuEntityAlwaysSchedule | MenuEntityWeeklySchedule

// BACKEND ONLINE OPERATIONAL MENU TYPES
interface OnlineOperationalBaseItem {
  XRefID: string
  label: string
}

export type OnlineOperationalMenuGroups = {
  [menuGroupXRefID: string]: OnlineOperationalMenuGroup
}
export type OnlineOperationalMenuItems = {
  [menuItemXRefID: string]: OnlineOperationalMenuItem
}
export type OnlineOperationalModifierGroups = {
  [modifierGroupXRefID: string]: OnlineOperationalModifierGroup
}
export type OnlineOperationalModifierOptions = {
  [modifierOptionXRefID: string]: OnlineOperationalModifierOption
}
export type OnlineOperationalMenuPages = {
  [menuPageXRefID: string]: OnlineOperationalMenuPage
}

export type MenuSource = 'RMM2' | 'RMM3'

export interface OnlineOperationalMenu {
  source: MenuSource
  sortedMenuPages: OnlineOperationalMenuRefItem[]
  refs: {
    menuPages: OnlineOperationalMenuPages
    menuGroups: OnlineOperationalMenuGroups
    menuItems: OnlineOperationalMenuItems
    modifierGroups: OnlineOperationalModifierGroups
    modifierOptions: OnlineOperationalModifierOptions
  }
}
export interface OnlineOperationalMenuPage extends OnlineOperationalBaseItem {
  menuGroupRefs: OnlineOperationalMenuRefItem[]
  schedule: MenuEntitySchedule
}

export enum OnlineOperationalMenuRefType {
  MENU_PAGES = 'menuPages',
  MENU_GROUPS = 'menuGroups',
  MENU_ITEMS = 'menuItems',
  MODIFIER_GROUPS = 'modifierGroups',
  MODIFIER_OPTIONS = 'modifierOptions',
}

export interface OnlineOperationalMenuRefItem {
  id: string
  type: OnlineOperationalMenuRefType
}

export interface OnlineOperationalMenuGroup extends OnlineOperationalBaseItem {
  description: string
  menuItemRefs: OnlineOperationalMenuRefItem[]
}

export interface OnlineOperationalMenuItem extends OnlineOperationalBaseItem {
  description: string
  price: string
  image?: string | null
  modifierGroupRefs: OnlineOperationalMenuRefItem[]
  isOutOfStock: boolean
}

export interface OnlineOperationalModifierGroup extends OnlineOperationalBaseItem {
  selectionMax: number
  selectionMin: number
  modifierOptionRefs: OnlineOperationalMenuRefItem[]
}
export interface OnlineOperationalModifierOption extends OnlineOperationalBaseItem {
  price: string
  isOutOfStock: boolean
  modifierGroupRefs: { [modifierGroupXRefID: string]: { price: string } }
}

export interface OperationalMenu {
  menuPages: OnlineOperationalMenuPages
  menuGroups: OnlineOperationalMenuGroups
  menuItems: OnlineOperationalMenuItems
  modifierGroups: OnlineOperationalModifierGroups
  modifierOptions: OnlineOperationalModifierOptions
}

export enum FieldTypes {
  TEXT_INPUT = 'text-input',
  NUM_INPUT = 'num-input',
  CHECKBOX = 'checkbox',
}

export interface TextInput {
  type: FieldTypes.TEXT_INPUT
  value: string
  validate: (val: string) => boolean
}

export interface NumInput {
  type: FieldTypes.NUM_INPUT
  value: number
  validate?: (val: number) => boolean
}

export interface Checkbox {
  type: FieldTypes.CHECKBOX
  value: boolean
  validate?: (val: boolean) => boolean
}

export type FormField = TextInput | NumInput | Checkbox

export interface FormFields {
  // key is the form field name
  [key: string]: FormField
  // form fields can have string or boolean values (...so far)
}

function formatVenue(venue: ServerVenue, branding: Branding): Venue {
  const {
    venueName,
    venueXRefID,
    venueCalorieStatement,
    venueAddress1,
    venueAddress2,
    venuePhone,
    venueLatitude,
    venueLongitude,
    venueState,
    venueCity,
    maxSchedulingDays,
    venueOrderingMethods,
    active,
    disableCutlery,
    disableTip,
    venueCountry,
    guestCheckoutEnabled,
    venueCurrency,
    venueIsTest,
    parentGroupName,
  } = venue
  const { bannerImage, logoImage, primaryColor, secondaryColor } = branding
  return {
    venueXRefID,
    active,
    disableCutlery,
    disableTip,
    phone: formatPhone(venuePhone),
    latitude: venueLatitude,
    longitude: venueLongitude,
    name: parseHTMLEntities(venueName).trim(),
    calorieStatement: venueCalorieStatement,
    venueOrderingMethods,
    maxSchedulingDays,
    parentGroupName,
    address: formatAddress({
      address1: venueAddress1,
      address2: venueAddress2,
      state: venueState,
      city: venueCity,
    }),
    venueCountry,
    branding: {
      bannerUrl: bannerImage?.image?.url ?? '',
      logoUrl: logoImage?.image?.url ?? '',
      primaryColor: primaryColor.hex,
      secondaryColor: secondaryColor.hex,
    },
    guestCheckoutEnabled,
    venueCurrency,
    venueIsTest,
  }
}

/**
 * helper to parse google places address to an easier to work with
 * @param {ReverseGeocodeResponse['data']} data - list of google addresses from location geocode query
 * @returns {OrderingAddress | null} if there are multiple matches in the google response, we parse the first one. if no matches are found, this helper returns null
 */
function orderingAddressAdapter(data: ReverseGeocodeResponse['data']): OrderingAddress | null {
  const [firstResult] = data.results
  if (firstResult != null) {
    const { address_components, geometry, formatted_address, place_id } = firstResult
    // https://developers.google.com/places/web-service/supported_types
    // https://developers.google.com/places/web-service/details
    const findAddressComponent = (
      ...addressTypes: (GeocodingAddressComponentType | AddressType)[]
    ): AddressComponent => {
      let addressComponentMatch: AddressComponent = {
        types: [],
        long_name: '',
        short_name: '',
      }

      for (const addressComponent of address_components) {
        for (const addressType of addressTypes) {
          if (addressComponent.types.includes(addressType)) {
            addressComponentMatch = addressComponent
            // stop looping on first match
            break
          }
        }
      }

      return addressComponentMatch
    }

    const streetNumber = findAddressComponent(
      GeocodingAddressComponentType.street_number
    )?.long_name
    const streetName = findAddressComponent(AddressType.route)?.long_name
    const premiseName = findAddressComponent(AddressType.premise)?.long_name

    // We need to build an address1 otherwise places in the POS and OOF will have addresses displayed improperly. In the
    // case that there is no streetName we will try to default to a premise. (Example of a google maps
    // premise with no street address is premiseName = Empire State Building)
    let address1 = ''
    if (streetName) {
      address1 = [streetNumber, streetName].join(' ').trim()
    } else if (premiseName) {
      address1 = premiseName
    }

    return {
      formattedAddress: formatted_address, // for display purposes
      address1,
      address2: '',
      city: findAddressComponent(
        AddressType.locality,
        AddressType.administrative_area_level_3,
        AddressType.sublocality,
        AddressType.sublocality_level_1
      )?.long_name,
      country: findAddressComponent(AddressType.country)?.short_name,
      postalCode: findAddressComponent(AddressType.postal_code)?.long_name,
      province: findAddressComponent(AddressType.administrative_area_level_1)?.short_name,
      lat: geometry.location.lat,
      lng: geometry.location.lng,
      googlePlaceID: place_id,
    }
  }

  return null
}

export { formatVenue, orderingAddressAdapter }
